const appConfig = {
    apiPrefix: process.env.REACT_APP_BASE_URL,
    appPlanURL: process.env.REACT_APP_PLAN_URL,
    googleRecaptchaKey: process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '',
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || '',
    googleProjectId: process.env.REACT_APP_GOOGLE_PROJECT_ID || '',
    authenticatedEntryPath: '/dash',
    unAuthenticatedEntryPath: '/',
    tourPath: '/',
    locale: 'pt',
    enableMock: false,
};

export default appConfig;
